// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src058678629/src/mvp-brochure-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("/codebuild/output/src058678629/src/mvp-brochure-site/src/pages/cookie_policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src058678629/src/mvp-brochure-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-menstrual-cycle-data-js": () => import("/codebuild/output/src058678629/src/mvp-brochure-site/src/pages/learn/menstrual-cycle-data.js" /* webpackChunkName: "component---src-pages-learn-menstrual-cycle-data-js" */),
  "component---src-pages-privacy-policy-js": () => import("/codebuild/output/src058678629/src/mvp-brochure-site/src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tos-js": () => import("/codebuild/output/src058678629/src/mvp-brochure-site/src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

