module.exports = [{
      plugin: require('/codebuild/output/src058678629/src/mvp-brochure-site/node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src058678629/src/mvp-brochure-site/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"palette":{"type":"light","primary":{"main":"#713cc2","dark":"#5d31a0","contrastText":"#ffffff"},"secondary":{"light":"#9A9292","main":"#273036","dark":"#000710","contrastText":"#ffffff"}},"status":{"danger":"orange"},"typography":{"fontFamily":["Muli","NotoSerif"]},"overrides":{"MuiButton":{"root":{"fontFamily":"Muli","fontWeight":600,"fontSize":18,"letterSpacing":"normal","minHeight":40,"textTransform":"none","color":"#9A9292","lineHeight":"1.5"},"contained":{"backgroundColor":"#713cc2","color":"white","&:hover":{"backgroundColor":"#6636af !important","color":"#fff !important"},"fontWeight":800},"outlined":{"border":"2px solid #9A9292","color":"#9A9292"}},"MuiInputLabel":{"shrink":{"fontSize":20}},"MuiAppBar":{"root":{"backgroundColor":"#f4f4f4 !important","color":"#9A9292"}}}}},
    },{
      plugin: require('/codebuild/output/src058678629/src/mvp-brochure-site/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"7144980","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('/codebuild/output/src058678629/src/mvp-brochure-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
